import { render, staticRenderFns } from "./powerBox16.vue?vue&type=template&id=fbdbfb06&scoped=true"
import script from "./powerBox16.vue?vue&type=script&lang=js"
export * from "./powerBox16.vue?vue&type=script&lang=js"
import style0 from "./powerBox16.vue?vue&type=style&index=0&id=fbdbfb06&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbdbfb06",
  null
  
)

export default component.exports